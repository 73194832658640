import React from 'react';
import styled from 'styled-components'
import { Fonts, Colors, Styles, Images } from '../../themes'
import { motion } from "framer-motion"
import Cookies from 'js-cookie';
var moment = require('moment');

const Wrapper = styled.div`
    background: white;
    box-shadow: 0px 4px 8px 0 ${Colors.B10};
    width: 100%;
    ${'' /* max-height: 224px; */}
    transition: .4s;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 50;
    &.forBottom{
        align-self: end;
    }
`

const TextArea = styled.div`
    padding: 16px 24px;
    max-height: 95vh;
    overflow: scroll;
`
/*
const Title = styled(Fonts.h4())`
   
    overflow: hidden;
    text-overflow: ellipsis;
`*/
const Title = styled(Fonts.h4())`
`
const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 45px;
    padding: 10px 0;
    &.pack{
        grid-template-columns: 1fr 45px 45px;
        align-items: start;
    }
    .arrow{
        justify-self: center;
    }
    
`
const Price = styled(Fonts.h4())`
    color:${Colors.Blue};
    justify-self: start;
`
const Required = styled.p`
    background: ${Colors.Light_Red};
    padding: 2px 4px;
    border-radius: 4px;
    color: ${Colors.Red};
    text-align: center;
    margin: 0;
`

const Button = styled(Styles.btnL())`
    margin-top: 8px;
`

const InfoArea = styled.div`
    padding: 12px 0;
    border-top: 1px solid ${Colors.B10};
`
const InfoName = styled(Fonts.h6())`
`
const Text = styled(Fonts.P_16())`
    padding: 4px 0;
    &.info{
        color: ${Colors.G2}
    }
`
const BtnArea = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    justify-items: center;
    width: 100%;
    bottom: 0px !important;
    z-index: 1000;
    margin: 16px;
    button{
        margin: 0 4px;
    }
`


const Notice = styled.div`
    overflow: hidden;
    border-top: 1px solid ${Colors.B10};
    padding: 16px 24px;
    li{
        font-size: 16px;
        color: ${Colors.B100};
        font-weight: 500;
        list-style: none;
    }
    li:hover{
        cursor: pointer;
    }
    li.disable{
        color: ${Colors.B100} !important;
        font-weight: 400;
    }
    li.disable:hover{
        cursor: default;
        pointer-events: none;
    }
`
const Alert = styled.p`
    color: ${Colors.Red};
    position: absolute;
    bottom: -5px;
    right: 24px;
`

const CardEventSignUpFixed = (props) => {
    const [open, setOpen] = React.useState(false)
    return (
        <Wrapper className={`${props.className}`} >
            <TextArea>
                {props.required === '必修'
                    ? <>
                        {/*<Title>{props.code}</Title>*/}
                        <Grid className='pack'>
                            <Title>{props.title}</Title>
                            {props.required === '必修' &&
                                <div><Required>{props.required}</Required></div>
                            }
                            <motion.div className='arrow' animate={{ scaleY: open ? 1 : -1 }} onClick={() => setOpen(!open)}>
                                <img src={Images.arrow_up} alt='toggle-icon' />
                            </motion.div>
                        </Grid>
                    </>
                    : <>
                        {/*<Title>{props.code}</Title>*/}
                        <Grid>
                            <Title>{props.title}</Title>
                            <motion.div className='arrow' animate={{ scaleY: open ? 1 : -1 }} onClick={() => setOpen(!open)}>
                                <img src={Images.arrow_up} alt='toggle-icon' />
                            </motion.div>
                        </Grid>
                    </>
                }
                {/*props.detail && <Text>{props.text}</Text>*/}
                {props.detail && <Price>{props.price}</Price>}
                {/* {props.pack && props.eventdetail &&
                    <motion.div animate={{ maxHeight: open ? 500 : 0, overflow: 'hidden', opacity: open ? 1 : 0 }}>
                    {props.data.map( (i,index) => (
                        <InfoArea>
                            <InfoName>{i.title}</InfoName>
                            <Text className='info'>上課時間：{i.time}</Text>
                        </InfoArea>
                    ))}
                    </motion.div>
                } */}
                {props.detail &&
                    <motion.div animate={{ maxHeight: open ? 200 : 0, overflow: 'hidden', opacity: open ? 1 : 0 }}>
                        {!props.eventdetail &&
                            <InfoArea>
                                <Text className='info'>課程講師：{props.teacher}</Text>
                                <Text className='info'>課程總長：{props.duration} {props.duration && '小時'}</Text>
                                <Text className='info'>課程地點：{props.location}</Text>
                                {props.content
                                    ? <Text className='info'>上課時間：{props.content}</Text>
                                    : <Text className='info'>上課時間：--</Text>
                                }
                                {/* {props.startTime && props.endTime 
                                    ? <>
                                        {moment(props.startTime).format('MM/DD') === moment(props.endTime).format('MM/DD')
                                            ? <Text className='info'>上課時間：{moment(props.startTime).format('YYYY/MM/DD (dd) a H:mm')} ~ {moment(props.endTime).format('a H:mm')}</Text>
                                            : <Text className='info'>上課時間：{moment(props.startTime).format('YYYY/MM/DD (dd) a H:mm')} ~ {moment(props.endTime).format('YYYY/MM/DD (dd) a H:mm')}</Text>
                                        }
                                    </>
                                    : <Text className='info'>上課時間：--</Text>
                                } */}
                                <Text className='info'>上課教室：{props.classroom}</Text>
                                <Text className='info'>票卷說明：{props.ticketExcerpt}</Text>
                            </InfoArea>
                        }
                    </motion.div>
                }
                {props.already
                    ? <Button className={`solid full-width disable`} >已付款</Button>
                    : <>
                        {props.eventdetail &&
                            <>
                                {!Cookies.get('rainbow') && !props.already
                                    ? <Button className={`solid full-width ${props.btnClass}`} onClick={props.Login}>登入以查看課程資訊</Button>
                                    : <Button className={`solid full-width ${props.btnClass}`} onClick={props.onClick}>查看課程資訊</Button>
                                }
                            </>
                        }

                    </>
                }
                {props.now === 1 && !props.eventdetail &&
                    <Button className={`solid full-width ${props.btn_1}`} onClick={props.onClick}>立即報名</Button>
                }
                {props.now === 2 &&
                    <BtnArea>
                        <Button className={`solid third full-width`} onClick={props.prev}>上一步</Button>
                        <Button className={`solid ${props.btn_2} full-width`} onClick={props.next}>下一步</Button>
                        {props.check === 'both' &&
                            <Alert>請檢查手機與 Email 輸入資料</Alert>
                        }
                        {props.check === 'email' &&
                            <Alert>請檢查 Email 輸入資料</Alert>
                        }
                        {props.check === 'cellphone' &&
                            <Alert>請檢查手機輸入資料</Alert>
                        }
                        {/*
                            props.check === 'address' &&
                            <Alert>請檢查地址輸入資料</Alert>
                        */}
                        {
                            props.check === 'gender' &&
                            <Alert>請檢查性別輸入資料</Alert>
                        }
                        {
                            props.check === 'name' &&
                            <Alert>請檢查性名輸入資料</Alert>
                        }
                        {
                            props.check === 'birth' &&
                            <Alert>請檢查生日輸入資料</Alert>
                        }
                        {
                            props.check === 'none' && props.btn_2 &&
                            <Alert>請檢查欄位並勾選同意</Alert>
                        }
                    </BtnArea>
                }
                {props.now === 3 &&
                    <BtnArea>
                        <Button className={`solid third full-width`} onClick={props.backDetail}>返回頁面</Button>
                        <Button className={`solid full-width`} onClick={props.goAccount}>前往會員頁</Button>
                    </BtnArea>
                }
                {props.now === 4 &&
                    <Button className={`solid full-width`} onClick={props.Retry}>重新報名</Button>
                }

            </TextArea>
            {props.eventdetail && !props.already && props.noti &&
                <motion.div animate={{ maxHeight: open ? 200 : 0, overflow: 'hidden', opacity: open ? 1 : 0 }}>
                    <Notice>
                        <li>報名須知</li>
                        {props.noti.split('．').map((item, index) => (
                            <>
                                {!(item === '' && index == 0) &&
                                    <li key={`title-${index}`}>{item === '' ? <br /> : `．${item}`}</li>
                                }
                            </>
                        ))}
                    </Notice>
                </motion.div>
            }
        </Wrapper>
    );
}

export default CardEventSignUpFixed;