import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components'
import { Images, Colors, Fonts, Metrics } from '../themes'
import { CalculateBtn, InputDateBar, CheckBar, InputBar } from '.'
import Slider from "react-slick"
import { DatePicker, Space, TimePicker } from 'antd';
import { isMobileOnly, isSafari } from 'react-device-detect';
import html2canvas from 'html2canvas';
var moment = require('moment');
const { Solar, Lunar, HolidayUtil } = require('lunar-javascript')

const Wrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding-bottom: 80px;
    .slick-slider{
        width: 100%;
    }
    .slick-next, .slick-prev{
        position: relative;
        display: flex;
        top: 0;
        left: 50%;
        background: #000;
    }

`
const Content = styled.div`
    position: relative;
    display: grid !important;
    justify-items: center;
    padding: 46px 24px 20px;
    width: 100%;
    box-sizing: border-box;
    .submit{
        margin-top: 24px;
        position: relative;
        z-index: 10000;
    }
    .checkBar{
        justify-self: center;
    }
`
const Title = styled.p`
    font-size: ${window.innerWidth < 360 ? '20px' : '24px'};
    font-weight:bold;
    color: ${Colors.B100};
    text-align: left;
    margin: 0 !important;
    padding-bottom: 12px;

`
const Text = styled.p`
    color: ${Colors.B100};
    font-size: 20px;
    margin: 0;
    letter-spacing: 0;
    line-height: 28px;
    max-height: 210px;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none;
    }

`
const BtnArea = styled.div`
    display: flex;
    justify-content: center;
    position: absolute;
    width: 100%;
    bottom: 0px !important;
    z-index: 1000;
    margin-bottom: 16px;
    button{
        margin: 0 4px;
    }
`
const ContentArea = styled.div`
    position: relative;
    
    grid-template-columns: 50% 50%;
    grid-gap: 6px;
    width: 340px;
    box-sizing: border-box;
    padding-bottom: 24px;
    .input-area,.black{
        padding: 8px 6px 4px 0;
    }
    @media (max-width: ${Metrics.mobile}){
        display: block;
        height: 100%;
    }
`

const Calculator = (props) => {
    const [origin_date, setOriginDate] = useState('');
    const [origin_friend_date, setOriginFriendDate] = useState('');
    const [imageLoaded, setImageLoaded] = useState(false);
    const [exchangeMonth, setExchangeMonth] = useState(false);
    const [origin_exchange, setOriginExchange] = useState(false);
    const [scenario, setScenario] = useState(0);
    const [date, setDate] = useState(props.parent_date);
    const [time, setTime] = useState(props.parent_time);
    const [name, setName] = useState(props.parent_name);
    const [gender, setGender] = useState(props.parent_gender);
    props.set_parent_date(date)
    props.set_parent_time(time)
    props.set_parent_name(name)
    props.set_parent_gender(gender)

    const [timeBak, setTimeBak] = useState('');
    const [check, setCheck] = useState(false);
    const [alert, setAlert] = useState(false);
    const [only_hour, setOnlyHour] = useState(false);
    const [friend_date, setFriendDate] = useState('2000-01-01');
    const [friend_time, setFriendTime] = useState('');
    const [friend_name, setFriendName] = useState('');
    const [friend_gender, setFriendGender] = useState('');
    const [friend_timeBak, setFriendTimeBak] = useState('');
    const [friend_check, setFriendCheck] = useState(false);
    const [friend_alert, setFriendAlert] = useState(false);
    const [friend_only_hour, setFriendOnlyHour] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const [advform, setAdvForm] = useState(
        {
            adviceDate: '',
            adviceName: '',
        }
    );
    const fsize = window.innerWidth < 360 ? '19px' : '20px';
    let sumyear = '';
    const [numberForm, setNumberForm] = useState({
        date: '',
        second_date: '',
        ldate: '',
        time: '',
        year: '',
        month: '',
        day: '',
        lyear: '',
        lmonth: '',
        lday: '',
        hour: '',
        minute: '',
        forget_minute: false,
        cross_year: false,
        only_hour: false,
        add_month: false,
        name: '',
        age: '',
        gender: '',
        BackNumbers: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
        SecondBackNumbers: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
        MainNumber: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
        Soul: ['', '', '', '', '', '', '', '', '', ''],
    });
    const [friendNumberForm, setFriendNumberForm] = useState({
        date: '',
        second_date: '',
        ldate: '',
        time: '',
        year: '',
        month: '',
        day: '',
        lyear: '',
        lmonth: '',
        lday: '',
        hour: '',
        minute: '',
        forget_minute: false,
        cross_year: false,
        only_hour: false,
        add_month: false,
        name: '',
        age: '',
        gender: '',
        BackNumbers: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
        SecondBackNumbers: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
        MainNumber: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
        Soul: ['', '', '', '', '', '', '', '', '', ''],
    });
    function calculateAll(date_value, time_value, only_hour_value, name_value, gender_value, form) {
        form.date = date_value;
        form.only_hour = only_hour_value;
        form.name = name_value;
        form.gender = gender_value;
        const gregorianYear = moment(date_value).format('YYYY');
        const gregorianMonth = moment(date_value).format('MM');
        const gregorianDay = moment(date_value).format('DD');

        const date1 = new Date(date_value);
        form.year = date1.getFullYear();
        form.month = date1.getMonth() + 1; // 月份從 0 開始，所以要加 1
        form.day = date1.getDate();

        const lunarDate = Solar.fromYmd(gregorianYear, gregorianMonth, gregorianDay).getLunar();
        form.ldate = lunarDate.getYear() + '-' + String(Math.abs(lunarDate.getMonth())).padStart(2, '0') + '-' + String(lunarDate.getDay()).padStart(2, '0')

        form.lyear = lunarDate.getYear();
        form.lmonth = Math.abs(lunarDate.getMonth());
        form.lday = lunarDate.getDay();
        form.add_month = (lunarDate.getMonth()) >= 0 ? false : true;
        form.second_date = form.add_month ? Lunar.fromYmd(form.lyear, form.lmonth + 1, form.lday).getSolar().toString() : form.date;
        form.time = time_value;
        form.hour = time_value != '' ? time_value.split(':')[0] : '';
        form.minute = only_hour_value ? '' : time_value != '' ? time_value.split(':')[1] : '';

        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1; // 月份從 0 開始，所以要加 1
        const day = currentDate.getDate();
        const lunarDateNow = Solar.fromYmd(year, month, day).getLunar();
        const year2 = lunarDateNow.getYear();
        const month2 = Math.abs(lunarDateNow.getMonth());
        const day2 = lunarDateNow.getDay();
        let syear = '';
        let age = year - form.year
        if (month > form.month) {
            syear = year;
        } else if (month < form.month) {
            syear = year - 1;
            age = age - 1
        } else {
            if (day > form.day) {
                syear = year;
            } else if (day < form.day) {
                syear = year - 1;
                age = age - 1
            } else {
                syear = year;
            }
        }
        if (form.year > form.lyear) {
            form.cross_year = true
        } else {
            form.cross_year = false
        }
        form.age = age;
        // let lyear = '';
        // if (month2 > form.lmonth) {
        //     lyear = year2;
        // } else if (month2 < form.lmonth) {
        //     lyear = year2 - 1;
        // } else {
        //     if (day2 > form.lday) {
        //         lyear = year2;
        //     } else if (day2 < form.lday) {
        //         lyear = year2 - 1;
        //     } else {
        //         lyear = year2;
        //     }
        // }
        const sdate = String(syear) + '-' + String(form.month).padStart(2, '0') + '-' + String(form.day).padStart(2, '0')
        const ldate = String(syear - (form.cross_year ? 1 : 0)) + '-' + String(form.lmonth).padStart(2, '0') + '-' + String(form.lday).padStart(2, '0')

        const NumberList = date_value.split("").filter(i => i !== '-')
        const NumberListLunar = form.ldate.split("").filter(i => i !== '-')
        const NumberList1 = time_value.split("").filter(i => i !== ':')
        const NumberList2 = NumberList.concat(NumberList1)
        const NumberList2Lunar = NumberListLunar.concat(NumberList1)
        const NumberList3 = sdate.split("").filter(i => i !== '-')
        const NumberList3Lunar = ldate.split("").filter(i => i !== '-')

        form.BackNumbers[0] = sum(NumberList.slice(0, 4).map(Number)).toString().padStart(2, '0').split("")
        form.BackNumbers[1] = sum(NumberList.slice(0, 6).map(Number)).toString().padStart(2, '0').split("")
        form.BackNumbers[2] = sum(NumberList.map(Number)).toString().padStart(2, '0').split("")
        form.BackNumbers[3] = sum(NumberList2.slice(0, 10).map(Number)).toString().padStart(2, '0').split("")
        form.BackNumbers[4] = sum(NumberList2.map(Number)).toString().padStart(2, '0').split("")
        form.BackNumbers[5] = sum(NumberListLunar.slice(0, 4).map(Number)).toString().padStart(2, '0').split("")
        form.BackNumbers[6] = sum(NumberListLunar.slice(0, 6).map(Number)).toString().padStart(2, '0').split("")
        form.BackNumbers[7] = sum(NumberListLunar.map(Number)).toString().padStart(2, '0').split("")
        form.BackNumbers[8] = sum(NumberList2Lunar.slice(0, 10).map(Number)).toString().padStart(2, '0').split("")
        form.BackNumbers[9] = sum(NumberList2Lunar.map(Number)).toString().padStart(2, '0').split("")
        form.BackNumbers[10] = sum(NumberList3.map(Number)).toString().padStart(2, '0').split("")
        form.BackNumbers[11] = sum(NumberList3Lunar.map(Number)).toString().padStart(2, '0').split("")

        for (let i = 0; i < 12; i++) {
            [form.SecondBackNumbers[i], form.MainNumber[i]] = calculateNumbers(form.BackNumbers[i], form.SecondBackNumbers[i], form.MainNumber[i])
        }
        for (let i = 0; i < 5; i++) {
            form.Soul[i] = CheckSoul(form.BackNumbers[i], form.SecondBackNumbers[i], form.MainNumber[i], NumberList)
        }
        for (let i = 5; i < 10; i++) {
            form.Soul[i] = CheckSoul(form.BackNumbers[i], form.SecondBackNumbers[i], form.MainNumber[i], NumberListLunar)
        }
        if (time_value == '') {
            form.Soul[3] = form.Soul[4] = form.Soul[8] = form.Soul[9] = 0
        }
    }

    const fdate1 = new Date(date);
    const fdate2 = new Date(friend_date);
    let exchange = false;
    if (fdate1.getTime() >= fdate2.getTime()) {
        if (fdate1.getTime() === fdate2.getTime()) {
            if (time !== '' && friend_time !== '') {
                if (time.split(':')[0] >= friend_time.split(':')[0]) {
                    if (time.split(':')[0] === friend_time.split(':')[0]) {
                        if (time.split(':')[1] > friend_time.split(':')[1]) {
                            exchange = true
                        }
                    } else exchange = true
                }
            } else if (time !== '') {
                exchange = true
            }
        } else exchange = true
    }

    if (exchange) {
        calculateAll(date, time, only_hour, name, gender, friendNumberForm);
        calculateAll(friend_date, friend_time, friend_only_hour, friend_name, friend_gender, numberForm);
    } else {
        calculateAll(date, time, only_hour, name, gender, numberForm);
        calculateAll(friend_date, friend_time, friend_only_hour, friend_name, friend_gender, friendNumberForm);
    }
    calculateYear(numberForm, friendNumberForm)
    function calculateYear(form1, form2) {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1; // 月份從 0 開始，所以要加 1
        const day = currentDate.getDate();

        if (month > form1.month) {
            sumyear = year;
        } else if (month < form1.month) {
            sumyear = year - 1;
        } else {
            if (day > form1.day) {
                sumyear = year;
            } else if (day < form1.day) {
                sumyear = year - 1;
            } else {
                sumyear = year;
            }
        }

        sumyear = sumyear -1
        let sdate = String(sumyear) + '-' + String(form1.month).padStart(2, '0') + '-' + String(form1.day).padStart(2, '0')
        let ldate = String(sumyear - (form1.cross_year ? 1 : 0)) + '-' + String(form1.lmonth).padStart(2, '0') + '-' + String(form1.lday).padStart(2, '0')
        let sdate1 = String(sumyear + 1) + '-' + String(form1.month).padStart(2, '0') + '-' + String(form1.day).padStart(2, '0')
        let ldate1 = String(sumyear + 1 - (form1.cross_year ? 1 : 0)) + '-' + String(form1.lmonth).padStart(2, '0') + '-' + String(form1.lday).padStart(2, '0')
        let sdate2 = String(sumyear + 2) + '-' + String(form1.month).padStart(2, '0') + '-' + String(form1.day).padStart(2, '0')
        let ldate2 = String(sumyear + 2 - (form1.cross_year ? 1 : 0)) + '-' + String(form1.lmonth).padStart(2, '0') + '-' + String(form1.lday).padStart(2, '0')
        let sdate3 = String(sumyear + 3) + '-' + String(form1.month).padStart(2, '0') + '-' + String(form1.day).padStart(2, '0')
        let ldate3 = String(sumyear + 3 - (form1.cross_year ? 1 : 0)) + '-' + String(form1.lmonth).padStart(2, '0') + '-' + String(form1.lday).padStart(2, '0')
        let sdate0 = String(sumyear - 1) + '-' + String(form1.month).padStart(2, '0') + '-' + String(form1.day).padStart(2, '0')
        let ldate0 = String(sumyear - 1 - (form1.cross_year ? 1 : 0)) + '-' + String(form1.lmonth).padStart(2, '0') + '-' + String(form1.lday).padStart(2, '0')

        let NumberList3 = sdate.split("").filter(i => i !== '-')
        let NumberList3Lunar = ldate.split("").filter(i => i !== '-')
        let NumberList4 = sdate1.split("").filter(i => i !== '-')
        let NumberList4Lunar = ldate1.split("").filter(i => i !== '-')
        let NumberList5 = sdate2.split("").filter(i => i !== '-')
        let NumberList5Lunar = ldate2.split("").filter(i => i !== '-')
        let NumberList6 = sdate3.split("").filter(i => i !== '-')
        let NumberList6Lunar = ldate3.split("").filter(i => i !== '-')
        let NumberList7 = sdate0.split("").filter(i => i !== '-')
        let NumberList7Lunar = ldate0.split("").filter(i => i !== '-')
        form1.BackNumbers[12] = sum(NumberList3.map(Number)).toString().padStart(2, '0').split("")
        form1.BackNumbers[13] = sum(NumberList3Lunar.map(Number)).toString().padStart(2, '0').split("")
        form1.BackNumbers[14] = sum(NumberList4.map(Number)).toString().padStart(2, '0').split("")
        form1.BackNumbers[15] = sum(NumberList4Lunar.map(Number)).toString().padStart(2, '0').split("")
        form1.BackNumbers[16] = sum(NumberList5.map(Number)).toString().padStart(2, '0').split("")
        form1.BackNumbers[17] = sum(NumberList5Lunar.map(Number)).toString().padStart(2, '0').split("")
        form1.BackNumbers[18] = sum(NumberList6.map(Number)).toString().padStart(2, '0').split("")
        form1.BackNumbers[19] = sum(NumberList6Lunar.map(Number)).toString().padStart(2, '0').split("")
        form1.BackNumbers[20] = sum(NumberList7.map(Number)).toString().padStart(2, '0').split("")
        form1.BackNumbers[21] = sum(NumberList7Lunar.map(Number)).toString().padStart(2, '0').split("")
        for (let i = 12; i < 22; i++) {
            [form1.SecondBackNumbers[i], form1.MainNumber[i]] = calculateNumbers(form1.BackNumbers[i], form1.SecondBackNumbers[i], form1.MainNumber[i])
        }
        sdate = String(sumyear) + '-' + String(form2.month).padStart(2, '0') + '-' + String(form2.day).padStart(2, '0')
        ldate = String(sumyear - (form2.cross_year ? 1 : 0)) + '-' + String(form2.lmonth).padStart(2, '0') + '-' + String(form2.lday).padStart(2, '0')
        sdate1 = String(sumyear + 1) + '-' + String(form2.month).padStart(2, '0') + '-' + String(form2.day).padStart(2, '0')
        ldate1 = String(sumyear + 1 - (form2.cross_year ? 1 : 0)) + '-' + String(form2.lmonth).padStart(2, '0') + '-' + String(form2.lday).padStart(2, '0')
        sdate2 = String(sumyear + 2) + '-' + String(form2.month).padStart(2, '0') + '-' + String(form2.day).padStart(2, '0')
        ldate2 = String(sumyear + 2 - (form2.cross_year ? 1 : 0)) + '-' + String(form2.lmonth).padStart(2, '0') + '-' + String(form2.lday).padStart(2, '0')
        sdate3 = String(sumyear + 3) + '-' + String(form2.month).padStart(2, '0') + '-' + String(form2.day).padStart(2, '0')
        ldate3 = String(sumyear + 3 - (form2.cross_year ? 1 : 0)) + '-' + String(form2.lmonth).padStart(2, '0') + '-' + String(form2.lday).padStart(2, '0')
        sdate0 = String(sumyear - 1) + '-' + String(form2.month).padStart(2, '0') + '-' + String(form2.day).padStart(2, '0')
        ldate0 = String(sumyear - 1 - (form2.cross_year ? 1 : 0)) + '-' + String(form2.lmonth).padStart(2, '0') + '-' + String(form2.lday).padStart(2, '0')
        NumberList3 = sdate.split("").filter(i => i !== '-')
        NumberList3Lunar = ldate.split("").filter(i => i !== '-')
        NumberList4 = sdate1.split("").filter(i => i !== '-')
        NumberList4Lunar = ldate1.split("").filter(i => i !== '-')
        NumberList5 = sdate2.split("").filter(i => i !== '-')
        NumberList5Lunar = ldate2.split("").filter(i => i !== '-')
        NumberList6 = sdate3.split("").filter(i => i !== '-')
        NumberList6Lunar = ldate3.split("").filter(i => i !== '-')
        NumberList7 = sdate0.split("").filter(i => i !== '-')
        NumberList7Lunar = ldate0.split("").filter(i => i !== '-')
        form2.BackNumbers[12] = sum(NumberList3.map(Number)).toString().padStart(2, '0').split("")
        form2.BackNumbers[13] = sum(NumberList3Lunar.map(Number)).toString().padStart(2, '0').split("")
        form2.BackNumbers[14] = sum(NumberList4.map(Number)).toString().padStart(2, '0').split("")
        form2.BackNumbers[15] = sum(NumberList4Lunar.map(Number)).toString().padStart(2, '0').split("")
        form2.BackNumbers[16] = sum(NumberList5.map(Number)).toString().padStart(2, '0').split("")
        form2.BackNumbers[17] = sum(NumberList5Lunar.map(Number)).toString().padStart(2, '0').split("")
        form2.BackNumbers[18] = sum(NumberList6.map(Number)).toString().padStart(2, '0').split("")
        form2.BackNumbers[19] = sum(NumberList6Lunar.map(Number)).toString().padStart(2, '0').split("")
        form2.BackNumbers[20] = sum(NumberList7.map(Number)).toString().padStart(2, '0').split("")
        form2.BackNumbers[21] = sum(NumberList7Lunar.map(Number)).toString().padStart(2, '0').split("")
        for (let i = 12; i < 22; i++) {
            [form2.SecondBackNumbers[i], form2.MainNumber[i]] = calculateNumbers(form2.BackNumbers[i], form2.SecondBackNumbers[i], form2.MainNumber[i])
        }
    }
    function calculateSquare(num1, num2) {
        let sum1 = parseInt(num1) + parseInt(num2)
        if (sum1 > 12) return (1) + (sum1 % 10)
        return sum1
    }
    //console.log(numberForm)
    const ResultSlider = useRef();
    const [sliderSettings] = useState({
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        speed: 300,
        cssEase: `linear`,
        adaptiveHeight: true,
        draggable: false,
        swipe: false,
    })
    const [index, setIndex] = useState(0);
    const gotoNext = () => {
        if (index === 1) {
            setOriginExchange(exchange)
            if (numberForm.add_month && friendNumberForm.add_month) {
                setScenario(4)
            } else {
                setScenario(2)
            }
            setOriginDate(numberForm.date)
            setOriginFriendDate(friendNumberForm.date)
            if (numberForm.add_month) {
                if (numberForm.second_date > friendNumberForm.date) {
                    setExchangeMonth(true)
                }
                if (numberForm.second_date === friendNumberForm.date) {
                    if (numberForm.hour > friendNumberForm.hour) {
                        setExchangeMonth(true)
                    }
                    if (numberForm.hour === friendNumberForm.hour) {
                        if (numberForm.minute > friendNumberForm.minute) {
                            setExchangeMonth(true)
                        }
                    }
                }
                if (!exchange) {
                    setDate(numberForm.second_date)
                    setTime(numberForm.time)
                    setOnlyHour(numberForm.only_hour)
                } else {
                    setFriendDate(numberForm.second_date)
                    setFriendTime(numberForm.time)
                    setFriendOnlyHour(numberForm.only_hour)
                }
            } else if (friendNumberForm.add_month) {
                if (!exchange) {
                    setFriendDate(friendNumberForm.second_date)
                    setFriendTime(friendNumberForm.time)
                    setFriendOnlyHour(friendNumberForm.only_hour)
                } else {
                    setDate(friendNumberForm.second_date)
                    setTime(friendNumberForm.time)
                    setOnlyHour(friendNumberForm.only_hour)
                }
            }
        }
        if (index === 2) {
            if (numberForm.add_month) {
                if (!origin_exchange) {
                    setFriendDate(numberForm.second_date)
                    setFriendTime(numberForm.time)
                    setFriendOnlyHour(numberForm.only_hour)
                } else {
                    setDate(numberForm.second_date)
                    setTime(numberForm.time)
                    setOnlyHour(numberForm.only_hour)
                }
            } else if (friendNumberForm.add_month) {
                if (!origin_exchange) {
                    setFriendDate(friendNumberForm.second_date)
                    setFriendTime(friendNumberForm.time)
                    setFriendOnlyHour(friendNumberForm.only_hour)
                } else {
                    setDate(friendNumberForm.second_date)
                    setTime(friendNumberForm.time)
                    setOnlyHour(friendNumberForm.only_hour)
                }
            }
        }
        if (index === 3) {
            //console.log(exchangeMonth,origin_exchange,origin_date,origin_friend_date)
            if (exchangeMonth) {
                if (!origin_exchange) {
                    setFriendDate(origin_friend_date)
                    setFriendTime(friendNumberForm.time)
                    setFriendOnlyHour(friendNumberForm.only_hour)
                } else {
                    setDate(origin_friend_date)
                    setTime(friendNumberForm.time)
                    setOnlyHour(friendNumberForm.only_hour)
                }
            } else {
                if (!origin_exchange) {
                    setDate(origin_date)
                    setTime(numberForm.time)
                    setOnlyHour(numberForm.only_hour)
                } else {
                    setFriendDate(origin_date)
                    setFriendTime(numberForm.time)
                    setFriendOnlyHour(numberForm.only_hour)
                }

            }
        }
        ResultSlider.current.slickNext()
        setIndex(index + 1)
    }
    const gotoPrev = () => {
        ResultSlider.current.slickPrev()
        setIndex(index - 1)
    }
    const Restart = () => {
        if (index != 1) {
            if (!origin_exchange) {
                setDate(origin_date)
                setFriendDate(origin_friend_date)
            } else {
                setDate(origin_friend_date)
                setFriendDate(origin_date)
            }
            setExchangeMonth(false)
            setScenario(0)
        }
        ResultSlider.current.slickGoTo(0)
        setIndex(0)
    }

    function onChange(e, dateString) {
        setAlert(false)
        // if (isMobileOnly) {
        //     setDate(e.target.value)
        //     if (e.target.value === '') {
        //         setDate('2000-01-01')
        //     }
        // } else {
        setDate(dateString)
        if (dateString === '') {
            setDate('2000-01-01')
        }
        //}
    }
    function onFriendChange(e, dateString) {
        setFriendAlert(false)
        // if (isMobileOnly) {
        //     setFriendDate(e.target.value)
        //     if (e.target.value === '') {
        //         setFriendDate('2000-01-01')
        //     }
        // } else {
        setFriendDate(dateString)
        if (dateString === '') {
            setFriendDate('2000-01-01')
        }
        //}

    }
    const handleTimeChange = (t) => {
        let hours = t.hours().toString().padStart(2, "0");
        let minutes = t.minutes().toString().padStart(2, "0");
        setTime(`${hours}:${minutes}`);
    };
    const handleTimeBlur = (t) => {
        // 在失去焦點時更新時間狀態
        setTime(t.target.value);
    };
    const handleCheck = (e) => {
        setCheck(!check)
        if (!check) {
            setTimeBak(time)
            setTime('');
        } else {
            setTime(timeBak);
        }
    };
    const handleCheckMinute = (e) => {
        setOnlyHour(!only_hour)
    };
    const handleFriendTimeChange = (t) => {
        let hours = t.hours().toString().padStart(2, "0");
        let minutes = t.minutes().toString().padStart(2, "0");
        setFriendTime(`${hours}:${minutes}`);
    };
    const handleFriendTimeBlur = (t) => {
        // 在失去焦點時更新時間狀態
        setFriendTime(t.target.value);
    };
    const handleFriendCheck = (e) => {
        setFriendCheck(!friend_check)
        if (!friend_check) {
            setFriendTimeBak(friend_time)
            setFriendTime('');
        } else {
            setFriendTime(friend_timeBak);
        }
    };
    const handleFriendCheckMinute = (e) => {
        setFriendOnlyHour(!friend_only_hour)
    };
    function Submit() {
        if (date && friend_date) {
            setAlert(false)
            setFriendAlert(false)
            ResultSlider.current.slickNext()
            setIndex(index + 1)
        } else if (date) {
            setFriendAlert(true)
        } else if (friend_date) {
            setAlert(true)
        } else {
            setFriendAlert(true)
            setAlert(true)
        }
    }

    function sum(arr) {
        var len = arr.length;
        if (len === 0) {
            return 0;
        } else if (len === 1) {
            return arr[0];
        } else {
            return arr[0] + sum(arr.slice(1));
        }
    }

    function calculateNumbers(BackNumbers, SecondBackNumbers, MainNumber) {
        if (sum(BackNumbers.map(Number)).toString().split("").length > 1) {
            SecondBackNumbers = sum(BackNumbers.map(Number)).toString().split("")
            MainNumber = sum(SecondBackNumbers.map(Number)).toString()
        } else {
            SecondBackNumbers = ''
            MainNumber = sum(BackNumbers.map(Number)).toString()
        }
        return [SecondBackNumbers, MainNumber]
    }

    function checkArrayValues(array1, array2) {
        // 检查第二个数组中的值是否都存在于第一个数组中
        const allValuesExist = array2.every(value => array1.includes(value));

        // 检查第二个数组中是否有任意一个值存在于第一个数组中
        const anyValueExists = array2.some(value => array1.includes(value));

        if (allValuesExist) {
            return 1; // 第二个数组中的所有值都在第一个数组中
        } else if (anyValueExists) {
            return 2; // 第二个数组中有值存在于第一个数组中
        } else {
            return 3; // 第二个数组中的所有值都不在第一个数组中
        }
    }

    function CheckSoul(BackNumbers, SecondBackNumbers, MainNumber, NumberCheckList) {
        let origin = []
        if (SecondBackNumbers.length > 1) {
            origin = BackNumbers.concat(SecondBackNumbers)
        } else {
            origin = BackNumbers
        }
        let result = origin.filter(function (element, index, arr) {
            return arr.indexOf(element) === index;
        });
        if (NumberCheckList.filter(i => i === MainNumber).length > 0) {
            if (checkArrayValues(NumberCheckList, result) == 1) {
                let NumberCheck = []
                for (let t = 0; t < 10; t++) {
                    if (NumberCheckList.filter(i => i === `${t}`).length > 2 && t !== 0) {
                        NumberCheck.push('6')
                    } else {
                        NumberCheck.push('7')
                    }
                }
                if ((NumberCheck.filter(i => i === "6").length > 0)) {
                    return 6
                } else {
                    return 7
                }
            } else if (checkArrayValues(NumberCheckList, result) == 2) {
                return 5
            } else {
                return 4
            }
        } else {
            if (checkArrayValues(NumberCheckList, result) == 1) {
                return 3
            } else if (checkArrayValues(NumberCheckList, result) == 2) {
                return 2
            } else {
                return 1
            }
        }
    }

    const handleDownload = () => {
        setImageLoaded(false);
        const element = document.getElementById('rainbowimg');
        html2canvas(element).then(canvas => {
            const link = document.createElement('a');
            link.download = '彩虹數字感情解碼.png';
            link.href = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
            link.click();
            setImageLoaded(true);
        });
    };
    const handleDownloadWhite = () => {
        const imageUrl = Images.emo_white; // 图片的URL

        // 创建一个新的<a>元素
        const link = document.createElement('a');
        link.href = imageUrl;
        link.download = '彩虹數字感情解碼.png'; // 下载的文件名

        // 将<a>元素添加到DOM，并模拟点击
        document.body.appendChild(link);
        link.click();

        // 清理工作，将<a>元素从DOM中移除
        document.body.removeChild(link);
    };
    function ImageNum(soul) {
        if (soul === 1) {
            return <img style={{
                width: '26px'
            }} alt='soul' src={Images.c1}></img>
        } else if (soul === 2) {
            return <img style={{
                width: '26px'
            }} alt='soul' src={Images.c2}></img>
        } else if (soul === 3) {
            return <img style={{
                width: '26px'
            }} alt='soul' src={Images.c3}></img>
        } else if (soul === 4) {
            return <img style={{
                width: '26px'
            }} alt='soul' src={Images.c4}></img>
        } else if (soul === 5) {
            return <img style={{
                width: '26px'
            }} alt='soul' src={Images.c5}></img>
        } else if (soul === 6) {
            return <img style={{
                width: '26px'
            }} alt='soul' src={Images.c6}></img>
        } else if (soul === 7) {
            return <img style={{
                width: '26px'
            }} alt='soul' src={Images.c7}></img>
        }
    }
    function NumPosition(BackNumbers, SecondBackNumbers, MainNumber, soul, fl) {
        return <>
            <div style={{ position: 'relative', flex: fl }}>
                <div
                    style={{
                        position: 'absolute',
                        top: '-15px',
                        left: '40px',
                        color: 'black',
                        fontSize: fsize,
                    }}>{BackNumbers.join("")}
                </div>
                <img src={Images.slash} alt='slash' style={{
                    position: 'absolute',
                    top: '5px',
                    left: '37px',
                    color: 'black',
                    fontSize: fsize,
                    width: '30px',
                }} />
                {SecondBackNumbers == "" ? <>
                    <div
                        style={{
                            position: 'absolute',
                            top: '12px',
                            left: '52px',
                            color: 'black',
                            fontSize: fsize,
                        }}>{MainNumber}
                    </div>
                    <div
                        style={{
                            position: 'absolute',
                            top: '42px',
                            left: '42px',
                            color: 'black',
                            fontSize: fsize,
                        }}>{ImageNum(soul)}
                    </div>
                </> : <div
                    style={{
                        position: 'absolute',
                        top: '14px',
                        left: `${parseInt(SecondBackNumbers.join("")) >= 10 ? '43px' : '50px'}`,
                        color: 'black',
                        fontSize: fsize,
                    }}>{SecondBackNumbers.join("")}<img src={Images.slash} alt='slash' style={{
                        position: 'absolute',
                        top: '20px',
                        left: '-3px',
                        color: 'black',
                        fontSize: fsize,
                        width: '30px',
                    }} />
                    <div
                        style={{
                            position: 'absolute',
                            top: '25px',
                            left: '10px',
                            color: 'black',
                            fontSize: fsize,
                        }}>{MainNumber}
                    </div>
                    <div
                        style={{
                            position: 'absolute',
                            top: '59px',
                            left: '0px',
                            color: 'black',
                            fontSize: fsize,
                        }}>{ImageNum(soul)}
                    </div>
                </div>}
            </div>
        </>
    }
    function ImageTri(index, slnumber) {
        return <div style={{ position: 'relative', flex: 1 }}>
            &nbsp;
            <img src={Images.lyear} alt="Image" />
            <div
                style={{
                    position: 'absolute',
                    top: '-1px',
                    left: '61px',
                    color: `${index === 3 ? 'red' : 'black'}`,
                    fontSize: '15px',
                }}>{slnumber[3]}
            </div>
            <div
                style={{
                    position: 'absolute',
                    top: '30px',
                    left: `${22 - 18 * (index === 2 ? 1 : 0)}px`,
                    color: `${index === 2 ? 'red' : 'black'}`,
                    fontSize: '15px',
                }}>{slnumber[2]}
            </div>
            <div
                style={{
                    position: 'absolute',
                    top: '30px',
                    left: '100px',
                    color: `${index === 4 ? 'red' : 'black'}`,
                    fontSize: '15px',
                }}>{slnumber[4]}
            </div>
            <div
                style={{
                    position: 'absolute',
                    top: '61px',
                    left: `${1 - 18 * (index === 1 ? 1 : 0)}px`,
                    color: `${index === 1 ? 'red' : 'black'}`,
                    fontSize: '15px',
                }}>{slnumber[1]}
            </div>
            <div
                style={{
                    position: 'absolute',
                    top: '61px',
                    left: '117px',
                    color: `${index === 5 ? 'red' : 'black'}`,
                    fontSize: '15px',
                }}>{slnumber[5]}
            </div>
            <div
                style={{
                    position: 'absolute',
                    top: '115px',
                    left: `${-15 - 18 * (index === 0 ? 1 : 0)}px`,
                    color: `${index === 0 ? 'red' : 'black'}`,
                    fontSize: '15px',
                }}>{slnumber[0]}
            </div>
            <div
                style={{
                    position: 'absolute',
                    top: '115px',
                    left: '135px',
                    color: `${index === 6 ? 'red' : 'black'}`,
                    fontSize: '15px',
                }}>{slnumber[6]}
            </div>
            <div
                style={{
                    position: 'absolute',
                    top: '125px',
                    left: `${37 - 18 * (index === 8 ? 1 : 0)}px`,
                    color: `${index === 8 ? 'red' : 'black'}`,
                    fontSize: '15px',
                }}>{slnumber[8]}
            </div>
            <div
                style={{
                    position: 'absolute',
                    top: '125px',
                    left: `${84 - 18 * (index === 7 ? 1 : 0)}px`,
                    color: `${index === 7 ? 'red' : 'black'}`,
                    fontSize: '15px',
                }}>{slnumber[7]}
            </div>
        </div>
    }
    function tri(form, sun) {
        let snumber = []
        let lnumber = []
        let sunIndex = 0
        let lunIndex = 0
        for (let i = 0; i < 9; i++) {
            let sn = ((Number(form.MainNumber[2]) + i) % 9) === 0 ? 9 : (Number(form.MainNumber[2]) + i) % 9
            if (Number(form.MainNumber[10]) === sn) {
                sn = '+' + form.BackNumbers[10].join("") + '/' + (form.SecondBackNumbers[10] == "" ? "" : form.SecondBackNumbers[10].join("") + "/") + form.MainNumber[10]
                sunIndex = i
            }
            snumber.push(sn)
            let ln = ((Number(form.MainNumber[7]) + i) % 9) === 0 ? 9 : (Number(form.MainNumber[7]) + i) % 9
            if (Number(form.MainNumber[11]) === ln) {
                ln = '-' + form.BackNumbers[11].join("") + '/' + (form.SecondBackNumbers[11] == "" ? "" : form.SecondBackNumbers[11].join("") + "/") + form.MainNumber[11]
                lunIndex = i
            }
            lnumber.push(ln)
        }
        return sun === 1 ? <>
            {ImageTri(sunIndex, snumber)}
        </> : <>
            {ImageTri(lunIndex, lnumber)}
        </>
    }
    function positions(form, sun) {
        const block1 = 7
        return sun === 1 ? <>
            {NumPosition(form.BackNumbers[0], form.SecondBackNumbers[0], form.MainNumber[0], form.Soul[0], block1)}
            {NumPosition(form.BackNumbers[1], form.SecondBackNumbers[1], form.MainNumber[1], form.Soul[1], block1)}
            {NumPosition(form.BackNumbers[2], form.SecondBackNumbers[2], form.MainNumber[2], form.Soul[2], block1 + (form.hour !== '' || form.minute !== '' ? 0 : 9))}
            {form.hour !== '' && <>
                {NumPosition(form.BackNumbers[3], form.SecondBackNumbers[3], form.MainNumber[3], form.Soul[3], block1 + (form.minute !== '' ? 0 : 9))}
            </>}
            {form.minute !== '' && <>
                {NumPosition(form.BackNumbers[4], form.SecondBackNumbers[4], form.MainNumber[4], form.Soul[4], 16)}
            </>}
        </> : <>
            {NumPosition(form.BackNumbers[5], form.SecondBackNumbers[5], form.MainNumber[5], form.Soul[5], block1)}
            {NumPosition(form.BackNumbers[6], form.SecondBackNumbers[6], form.MainNumber[6], form.Soul[6], block1)}
            {NumPosition(form.BackNumbers[7], form.SecondBackNumbers[7], form.MainNumber[7], form.Soul[7], block1 + (form.hour !== '' || form.minute !== '' ? 0 : 10))}
            {form.hour !== '' && <>
                {NumPosition(form.BackNumbers[8], form.SecondBackNumbers[8], form.MainNumber[8], form.Soul[8], block1 + (form.minute !== '' ? 0 : 10))}
            </>}
            {form.minute !== '' && <>
                {NumPosition(form.BackNumbers[9], form.SecondBackNumbers[9], form.MainNumber[9], form.Soul[9], 17)}
            </>}
        </>
    }
    function word(form) {
        return <>
            <Text>&nbsp;</Text>
            <div style={{ display: 'flex' }}>
                <div style={{ position: 'relative', flex: 100 }}>
                    <Title>+{form.date.replace('-', ' . ').replace('-', ' . ')}{form.hour !== '' && (' . ' + form.hour)}{form.minute !== '' && (' . ' + form.minute)}{<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}</Title>
                    <div style={{ display: 'flex' }}>
                        &nbsp;&nbsp;
                        {positions(form, 1)}
                    </div>
                </div>
                {tri(form, 1)}
            </div>
            <Text>&nbsp;</Text>
            <div style={{ display: 'flex' }}>
                <div style={{ position: 'relative', flex: 100 }}>
                    <Title>-{form.ldate.replace('-', ' . ').replace('-', ' . ')}{form.hour !== '' && (' . ' + form.hour)}{form.minute !== '' && (' . ' + form.minute)}{<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}</Title>
                    <div style={{ display: 'flex' }}>
                        {positions(form, 0)}
                    </div>
                </div>
                {tri(form, 0)}
            </div>
        </>
    }
    function ImageSquareBigSpace() {
        return <div style={{ position: 'relative', flex: 1 }}>
            &nbsp;
            <img style={{ width: '100px' }} src={Images.square} alt="Image" />
        </div>
    }
    function ImageSquareBig(i, j, k, l) {
        return <div style={{ position: 'relative', flex: 1 }}>
            &nbsp;
            <img style={{ width: '100px' }} src={Images.square} alt="Image" />
            <div
                style={{
                    position: 'absolute',
                    top: '27px',
                    left: '28px',
                    color: 'black',
                    fontSize: '22px',
                }}>{i}
            </div>
            <div
                style={{
                    position: 'absolute',
                    top: '27px',
                    left: '65px',
                    color: 'black',
                    fontSize: '22px',
                }}>{j}
            </div>
            <div
                style={{
                    position: 'absolute',
                    top: '67px',
                    left: '28px',
                    color: 'black',
                    fontSize: '22px',
                }}>{k}
            </div>
            <div
                style={{
                    position: 'absolute',
                    top: '67px',
                    left: '65px',
                    color: 'black',
                    fontSize: '22px',
                }}>{l}
            </div>
            <div
                style={{
                    position: 'absolute',
                    top: '-7px',
                    left: calculateSquare(i, j) >= 10 ? '40px' : '47px',
                    color: 'black',
                    fontSize: '22px',
                }}>{calculateSquare(i, j)}
            </div>
            <div
                style={{
                    position: 'absolute',
                    top: '100px',
                    left: calculateSquare(k, l) >= 10 ? '40px' : '47px',
                    color: 'black',
                    fontSize: '22px',
                }}>{calculateSquare(k, l)}
            </div>
            <div
                style={{
                    position: 'absolute',
                    top: '47px',
                    left: calculateSquare(i, l) >= 10 ? '-26px' : '-19px',
                    color: 'black',
                    fontSize: '22px',
                }}>{calculateSquare(i, l)}
            </div>
            <div
                style={{
                    position: 'absolute',
                    top: '47px',
                    left: calculateSquare(k, j) >= 10 ? '103px' : '110px',
                    color: 'black',
                    fontSize: '22px',
                }}>{calculateSquare(k, j)}
            </div>
        </div>
    }
    function ImageSquare(i, j, k, l) {
        return <div style={{ position: 'relative', flex: 1 }}>
            &nbsp;
            <img style={{ width: '90px' }} src={Images.square} alt="Image" />
            <div
                style={{
                    position: 'absolute',
                    top: '25px',
                    left: '25px',
                    color: 'black',
                    fontSize: '20px',
                }}>{i}
            </div>
            <div
                style={{
                    position: 'absolute',
                    top: '25px',
                    left: '55px',
                    color: 'black',
                    fontSize: '20px',
                }}>{j}
            </div>
            <div
                style={{
                    position: 'absolute',
                    top: '63px',
                    left: '25px',
                    color: 'black',
                    fontSize: '20px',
                }}>{k}
            </div>
            <div
                style={{
                    position: 'absolute',
                    top: '63px',
                    left: '55px',
                    color: 'black',
                    fontSize: '20px',
                }}>{l}
            </div>
            <div
                style={{
                    position: 'absolute',
                    top: '-10px',
                    left: calculateSquare(i, j) >= 10 ? '33px' : '40px',
                    color: 'black',
                    fontSize: '20px',
                }}>{calculateSquare(i, j)}
            </div>
            <div
                style={{
                    position: 'absolute',
                    top: '95px',
                    left: calculateSquare(k, l) >= 10 ? '33px' : '40px',
                    color: 'black',
                    fontSize: '20px',
                }}>{calculateSquare(k, l)}
            </div>
            <div
                style={{
                    position: 'absolute',
                    top: '43px',
                    left: calculateSquare(i, l) >= 10 ? '-25px' : '-19px',
                    color: 'black',
                    fontSize: '20px',
                }}>{calculateSquare(i, l)}
            </div>
            <div
                style={{
                    position: 'absolute',
                    top: '43px',
                    left: calculateSquare(k, j) >= 10 ? '90px' : '96px',
                    color: 'black',
                    fontSize: '20px',
                }}>{calculateSquare(k, j)}
            </div>
        </div>
    }
    function lnumber(form, i, sl) {
        return sl + form.BackNumbers[i].join("") + '/' + (form.SecondBackNumbers[i] == "" ? "" : form.SecondBackNumbers[i].join("") + "/") + form.MainNumber[i]
    }
    function ImageSquareList(form1, form2) {
        let planA = true;
        if (form1.month > form2.month) {
            planA = true;
        } else if (form1.month < form2.month) {
            planA = false;
        } else {
            if (form1.day > form2.day) {
                planA = true;
            } else if (form1.day < form2.day) {
                planA = false;
            } else {
                planA = true;
                if (form1.time != '' && form2.time != '') {
                    if (form1.hour > form2.hour) {
                        planA = true;
                    } else if (form1.hour < form2.hour) {
                        planA = false;
                    } else {
                        if (form1.minute > form2.minute) {
                            planA = true;
                        } else if (form1.minute < form2.minute) {
                            planA = false;
                        } else {
                            planA = true;
                        }
                    }
                } else if (form1.time != '') {
                    planA = true;
                } else if (form2.time != '') {
                    planA = false;
                } else {
                    planA = true;
                }
            }
        }
        return <div style={{ position: 'relative', flex: 1 }}>
            &nbsp;
            <img style={{ width: '900px' }} src={Images.squarelist} alt="Image" />
            <div style={{ fontSize: '28px', position: 'absolute', top: '-10px', left: '4px' }}>
                {form1.name}
            </div>
            <div style={{ fontSize: '28px', position: 'absolute', top: '315px', left: '4px' }}>
                {form2.name}
            </div>
            <div
                style={{
                    position: 'absolute',
                    top: '25px',
                    left: '35px',
                    color: 'black',
                    fontSize: '23px',
                }}>{sumyear + '.' + form1.month + '.' + form1.day}
            </div>
            <div
                style={{
                    position: 'absolute',
                    top: '25px',
                    left: '324px',
                    color: 'black',
                    fontSize: '23px',
                }}>{(parseInt(sumyear) + 1).toString() + '.' + form1.month + '.' + form1.day}
            </div>
            <div
                style={{
                    position: 'absolute',
                    top: '25px',
                    left: '615px',
                    color: 'black',
                    fontSize: '23px',
                }}>{(parseInt(sumyear) + 2).toString() + '.' + form1.month + '.' + form1.day}
            </div>
            <div
                style={{
                    position: 'absolute',
                    top: '260px',
                    left: '178px',
                    color: 'black',
                    fontSize: '23px',
                }}>{(parseInt(sumyear) + (planA ? 1 : 0)).toString() + '.' + form2.month + '.' + form2.day}
            </div>
            <div
                style={{
                    position: 'absolute',
                    top: '260px',
                    left: '468px',
                    color: 'black',
                    fontSize: '23px',
                }}>{(parseInt(sumyear) + (planA ? 2 : 1)).toString() + '.' + form2.month + '.' + form2.day}
            </div>
            <div
                style={{
                    position: 'absolute',
                    top: '260px',
                    left: '766px',
                    color: 'black',
                    fontSize: '23px',
                }}>{(parseInt(sumyear) + (planA ? 3 : 2)).toString() + '.' + form2.month + '.' + form2.day}
            </div>
            <div
                style={{
                    position: 'absolute',
                    top: '10px',
                    left: '138px',
                    color: 'red',
                    fontSize: '23px',
                }}>{lnumber(form1, 12, '+')}
            </div>
            <div
                style={{
                    position: 'absolute',
                    top: '36px',
                    left: '143px',
                    color: 'red',
                    fontSize: '23px',
                }}>{lnumber(form1, 13, '-')}
            </div>
            <div
                style={{
                    position: 'absolute',
                    top: '10px',
                    left: '433px',
                    color: 'red',
                    fontSize: '23px',
                }}>{lnumber(form1, 14, '+')}
            </div>
            <div
                style={{
                    position: 'absolute',
                    top: '36px',
                    left: '438px',
                    color: 'red',
                    fontSize: '23px',
                }}>{lnumber(form1, 15, '-')}
            </div>
            <div
                style={{
                    position: 'absolute',
                    top: '10px',
                    left: '725px',
                    color: 'red',
                    fontSize: '23px',
                }}>{lnumber(form1, 16, '+')}
            </div>
            <div
                style={{
                    position: 'absolute',
                    top: '36px',
                    left: '730px',
                    color: 'red',
                    fontSize: '23px',
                }}>{lnumber(form1, 17, '-')}
            </div>
            <div
                style={{
                    position: 'absolute',
                    top: '250px',
                    left: '280px',
                    color: 'red',
                    fontSize: '23px',
                }}>{lnumber(form2, 12 + (planA ? 2 : 0), '+')}
            </div>
            <div
                style={{
                    position: 'absolute',
                    top: '276px',
                    left: '285px',
                    color: 'red',
                    fontSize: '23px',
                }}>{lnumber(form2, 13 + (planA ? 2 : 0), '-')}
            </div>
            <div
                style={{
                    position: 'absolute',
                    top: '250px',
                    left: '580px',
                    color: 'red',
                    fontSize: '23px',
                }}>{lnumber(form2, 14 + (planA ? 2 : 0), '+')}
            </div>
            <div
                style={{
                    position: 'absolute',
                    top: '276px',
                    left: '585px',
                    color: 'red',
                    fontSize: '23px',
                }}>{lnumber(form2, 15 + (planA ? 2 : 0), '-')}
            </div>
            <div
                style={{
                    position: 'absolute',
                    top: '250px',
                    left: '884px',
                    color: 'red',
                    fontSize: '23px',
                }}>{lnumber(form2, 16 + (planA ? 2 : 0), '+')}
            </div>
            <div
                style={{
                    position: 'absolute',
                    top: '276px',
                    left: '889px',
                    color: 'red',
                    fontSize: '23px',
                }}>{lnumber(form2, 17 + (planA ? 2 : 0), '-')}
            </div>
            <div
                style={{
                    position: 'absolute',
                    top: '250px',
                    left: '10px',
                    color: 'red',
                    fontSize: '23px',
                }}>{lnumber(form2, (planA ? 12 : 20), '+')}
            </div>
            <div
                style={{
                    position: 'absolute',
                    top: '276px',
                    left: '15px',
                    color: 'red',
                    fontSize: '23px',
                }}>{lnumber(form2, (planA ? 13 : 21), '-')}
            </div>
            <div style={{ position: 'absolute', top: '33%', left: '6.1%' }}>
                {ImageSquare(numberForm.MainNumber[12], friendNumberForm.MainNumber[(planA ? 12 : 20)], numberForm.MainNumber[13], friendNumberForm.MainNumber[(planA ? 13 : 21)])}
            </div>
            <div style={{ position: 'absolute', top: '33%', left: '22.3%' }}>
                {ImageSquare(numberForm.MainNumber[12], friendNumberForm.MainNumber[(planA ? 14 : 12)], numberForm.MainNumber[13], friendNumberForm.MainNumber[(planA ? 15 : 13)])}
            </div>
            <div style={{ position: 'absolute', top: '33%', left: '38.2%' }}>
                {ImageSquare(numberForm.MainNumber[14], friendNumberForm.MainNumber[(planA ? 14 : 12)], numberForm.MainNumber[15], friendNumberForm.MainNumber[(planA ? 15 : 13)])}
            </div>
            <div style={{ position: 'absolute', top: '33%', left: '54.4%' }}>
                {ImageSquare(numberForm.MainNumber[14], friendNumberForm.MainNumber[(planA ? 16 : 14)], numberForm.MainNumber[15], friendNumberForm.MainNumber[(planA ? 17 : 15)])}
            </div>
            <div style={{ position: 'absolute', top: '33%', left: '70.6%' }}>
                {ImageSquare(numberForm.MainNumber[16], friendNumberForm.MainNumber[(planA ? 16 : 14)], numberForm.MainNumber[17], friendNumberForm.MainNumber[(planA ? 17 : 15)])}
            </div>
            <div style={{ position: 'absolute', top: '33%', left: '87.1%' }}>
                {ImageSquare(numberForm.MainNumber[16], friendNumberForm.MainNumber[(planA ? 18 : 16)], numberForm.MainNumber[17], friendNumberForm.MainNumber[(planA ? 19 : 17)])}
            </div>
        </div>
    }
    const handleAdvChange = (e) => {
        if (e.target.name !== 'adviceDate') {
            e.preventDefault()
            e.stopPropagation()
        }
        setAdvForm({
            ...advform,
            [e.target.name]: e.target.value,
        });
    };
    const handleChange = (e) => {

        e.preventDefault()
        e.stopPropagation()
        if (e.target.name === 'name') {
            setName(e.target.value)
        } else if (e.target.name === 'friend_name') {
            setFriendName(e.target.value)
        } else if (e.target.name === 'gender') {
            setGender(e.target.value)
        } else if (e.target.name === 'friend_gender') {
            setFriendGender(e.target.value)
        }
    };
    function allChinese(val) {
        let reg = new RegExp("[\\u4E00-\\u9FFF]+$", "g");
        if (reg.test(val)) return true
        return false
    }
    function PreloadImage(props) {
        const { src, alt } = props;
    
        useEffect(() => {
          const image = new Image();
          image.src = src;
    
          image.onload = () => {
            setImageLoaded(true);
          };
    
          return () => {
            image.onload = null;
          };
        }, [src]);
    
        return (
          <img src={imageLoaded ? src : Images.loading} alt={alt} style={{ width: imageLoaded ? "100%" : '' }} />
        );
    }
    const Loading = () => {
    
        return (
          <div className="page-loading">
            <script src="https://cdn.lordicon.com/bhenfmcm.js"></script>
            <div className="-load">
              <div className="-icon">
                <lord-icon src="https://cdn.lordicon.com/xjovhxra.json" trigger="loop" delay="300" colors="primary:#ffffff,secondary:#ffffff" style={{ width: '60px', height: '60px' }}></lord-icon>
              </div>
              <div className="-font">
                <span>l</span>
                <span>o</span>
                <span>a</span>
                <span>d</span>
                <span>i</span>
                <span>n</span>
                <span>g</span>
              </div>
            </div>
          </div>
        );
    };
    function renderAll() {
        return <>
            {!imageLoaded && <Loading />}
            <PreloadImage style={{ width: '100%' }} src={Images.word_emo} alt="Image" />
            {imageLoaded && (<>
            <div style={{ fontSize: '28px', position: 'absolute', top: '17%', left: '14%' }}>
                {numberForm.name}
            </div>
            <div style={{ fontSize: '28px', position: 'absolute', top: '17%', left: '32%' }}>
                {numberForm.age}
            </div>
            <div style={{ fontSize: '28px', position: 'absolute', top: '17%', left: '43%' }}>
                {numberForm.gender}
            </div>
            <div style={{ fontSize: '28px', position: 'absolute', top: '17%', left: '58%' }}>
                {friendNumberForm.name}
            </div>
            <div style={{ fontSize: '28px', position: 'absolute', top: '17%', left: '76%' }}>
                {friendNumberForm.age}
            </div>
            <div style={{ fontSize: '28px', position: 'absolute', top: '17%', left: '87%' }}>
                {friendNumberForm.gender}
            </div>
            <div style={{ position: 'absolute', top: '18.5%', left: '6%' }}>
                {word(numberForm)}
            </div>
            <div style={{ position: 'absolute', top: '18.5%', left: '51%' }}>
                {word(friendNumberForm)}
            </div>
            <div style={{ position: 'absolute', top: '43.5%', left: '12%' }}>
                {ImageSquareBig(numberForm.MainNumber[0], friendNumberForm.MainNumber[0], numberForm.MainNumber[5], friendNumberForm.MainNumber[5])}
            </div>
            <div style={{ position: 'absolute', top: '52.5%', left: '20%' }}>
                {ImageSquareBig(numberForm.MainNumber[0], friendNumberForm.MainNumber[1], numberForm.MainNumber[5], friendNumberForm.MainNumber[6])}
            </div>
            <div style={{ position: 'absolute', top: '43.5%', left: '29%' }}>
                {ImageSquareBig(numberForm.MainNumber[1], friendNumberForm.MainNumber[1], numberForm.MainNumber[6], friendNumberForm.MainNumber[6])}
            </div>
            <div style={{ position: 'absolute', top: '52.5%', left: '37%' }}>
                {ImageSquareBig(numberForm.MainNumber[1], friendNumberForm.MainNumber[2], numberForm.MainNumber[6], friendNumberForm.MainNumber[7])}
            </div>
            <div style={{ position: 'absolute', top: '43.5%', left: '45%' }}>
                {ImageSquareBig(numberForm.MainNumber[2], friendNumberForm.MainNumber[2], numberForm.MainNumber[7], friendNumberForm.MainNumber[7])}
            </div>
            {
                friendNumberForm.hour !== '' ? <>
                    <div style={{ position: 'absolute', top: '52.5%', left: '53%' }}>
                        {ImageSquareBig(numberForm.MainNumber[2], friendNumberForm.MainNumber[3], numberForm.MainNumber[7], friendNumberForm.MainNumber[8])}
                    </div>
                </> : <>
                    <div style={{ position: 'absolute', top: '52.5%', left: '53%' }}>
                        {ImageSquareBigSpace()}
                    </div>
                </>
            }
            {
                numberForm.hour !== '' && friendNumberForm.hour !== '' ? <>
                    <div style={{ position: 'absolute', top: '43.5%', left: '61.5%' }}>
                        {ImageSquareBig(numberForm.MainNumber[3], friendNumberForm.MainNumber[3], numberForm.MainNumber[8], friendNumberForm.MainNumber[8])}
                    </div>
                </> : <>
                    <div style={{ position: 'absolute', top: '43.5%', left: '61.5%' }}>
                        {ImageSquareBigSpace()}
                    </div>
                </>
            }
            {
                numberForm.hour !== '' && friendNumberForm.minute !== '' ? <>
                    <div style={{ position: 'absolute', top: '52.5%', left: '70%' }}>
                        {ImageSquareBig(numberForm.MainNumber[3], friendNumberForm.MainNumber[4], numberForm.MainNumber[8], friendNumberForm.MainNumber[9])}
                    </div>
                </> : <>
                    <div style={{ position: 'absolute', top: '52.5%', left: '70%' }}>
                        {ImageSquareBigSpace()}
                    </div>
                </>
            }
            {
                numberForm.minute !== '' && friendNumberForm.minute !== '' ? <>
                    <div style={{ position: 'absolute', top: '43.5%', left: '78%' }}>
                        {ImageSquareBig(numberForm.MainNumber[4], friendNumberForm.MainNumber[4], numberForm.MainNumber[9], friendNumberForm.MainNumber[9])}
                    </div>
                </> : <>
                    <div style={{ position: 'absolute', top: '43.5%', left: '78%' }}>
                        {ImageSquareBigSpace()}
                    </div>
                </>
            }
            <div style={{ position: 'absolute', top: '66%', left: '6.8%' }}>
                {ImageSquareList(numberForm, friendNumberForm)}
            </div>
            <div style={{ fontSize: '22px', position: 'absolute', top: '90.4%', left: '82.5%' }}>
                {advform.adviceDate}
            </div>
            <div style={{ fontSize: '28px', position: 'absolute', top: '90.2%', left: '62.5%' }}>
                {advform.adviceName}
            </div></>)}
        </>
    }
    function mobileRenderAll() {
        return <>
            {!imageLoaded && <Loading />}
            <PreloadImage style={{ width: '100%' }} src={Images.word_emo} alt="Image" />
            {imageLoaded && (<>
            <div style={{ transform: 'scale(0.4)', fontSize: '28px', position: 'absolute', top: '18.5%', left: '10%' }}>
                {numberForm.name}
            </div>
            <div style={{ transform: 'scale(0.4)', fontSize: '28px', position: 'absolute', top: '18.5%', left: '30%' }}>
                {numberForm.age}
            </div>
            <div style={{ transform: 'scale(0.4)', fontSize: '28px', position: 'absolute', top: '18.5%', left: '41%' }}>
                {numberForm.gender}
            </div>
            <div style={{ transform: 'scale(0.4)', fontSize: '28px', position: 'absolute', top: '18.5%', left: '51%' }}>
                {friendNumberForm.name}
            </div>
            <div style={{ transform: 'scale(0.4)', fontSize: '28px', position: 'absolute', top: '18.5%', left: '70%' }}>
                {friendNumberForm.age}
            </div>
            <div style={{ transform: 'scale(0.4)', fontSize: '28px', position: 'absolute', top: '18.5%', left: '81%' }}>
                {friendNumberForm.gender}
            </div>
            <div style={{ transform: 'scale(0.35)', position: 'absolute', top: '4.3%', left: numberForm.minute!=''?'-25%':'-15%' }}>
                {word(numberForm)}
            </div>
            <div style={{ transform: 'scale(0.35)', position: 'absolute', top: '4.3%', left: friendNumberForm.minute!=''?'15%':'25%', whiteSpace: 'nowrap'  }}>
                {word(friendNumberForm)}
            </div>
            <div style={{ transform: 'scale(0.4)', position: 'absolute', top: '39.5%', left: '6%' }}>
                {ImageSquareBig(numberForm.MainNumber[0], friendNumberForm.MainNumber[0], numberForm.MainNumber[5], friendNumberForm.MainNumber[5])}
            </div>
            <div style={{ transform: 'scale(0.4)', position: 'absolute', top: '47.5%', left: '14%' }}>
                {ImageSquareBig(numberForm.MainNumber[0], friendNumberForm.MainNumber[1], numberForm.MainNumber[5], friendNumberForm.MainNumber[6])}
            </div>
            <div style={{ transform: 'scale(0.4)', position: 'absolute', top: '39.5%', left: '23%' }}>
                {ImageSquareBig(numberForm.MainNumber[1], friendNumberForm.MainNumber[1], numberForm.MainNumber[6], friendNumberForm.MainNumber[6])}
            </div>
            <div style={{ transform: 'scale(0.4)', position: 'absolute', top: '47.5%', left: '31%' }}>
                {ImageSquareBig(numberForm.MainNumber[1], friendNumberForm.MainNumber[2], numberForm.MainNumber[6], friendNumberForm.MainNumber[7])}
            </div>
            <div style={{ transform: 'scale(0.4)', position: 'absolute', top: '39.5%', left: '39%' }}>
                {ImageSquareBig(numberForm.MainNumber[2], friendNumberForm.MainNumber[2], numberForm.MainNumber[7], friendNumberForm.MainNumber[7])}
            </div>
            {
                friendNumberForm.hour !== '' ? <>
                    <div style={{ transform: 'scale(0.4)', position: 'absolute', top: '47.5%', left: '47%' }}>
                        {ImageSquareBig(numberForm.MainNumber[2], friendNumberForm.MainNumber[3], numberForm.MainNumber[7], friendNumberForm.MainNumber[8])}
                    </div>
                </> : <>
                    <div style={{ transform: 'scale(0.4)', position: 'absolute', top: '47.5%', left: '47%' }}>
                        {ImageSquareBigSpace()}
                    </div>
                </>
            }
            {
                numberForm.hour !== '' && friendNumberForm.hour !== '' ? <>
                    <div style={{ transform: 'scale(0.4)', position: 'absolute', top: '39.5%', left: '55.5%' }}>
                        {ImageSquareBig(numberForm.MainNumber[3], friendNumberForm.MainNumber[3], numberForm.MainNumber[8], friendNumberForm.MainNumber[8])}
                    </div>
                </> : <>
                    <div style={{ transform: 'scale(0.4)', position: 'absolute', top: '39.5%', left: '55.5%' }}>
                        {ImageSquareBigSpace()}
                    </div>
                </>
            }
            {
                numberForm.hour !== '' && friendNumberForm.minute !== '' ? <>
                    <div style={{ transform: 'scale(0.4)', position: 'absolute', top: '47.5%', left: '64%' }}>
                        {ImageSquareBig(numberForm.MainNumber[3], friendNumberForm.MainNumber[4], numberForm.MainNumber[8], friendNumberForm.MainNumber[9])}
                    </div>
                </> : <>
                    <div style={{ transform: 'scale(0.4)', position: 'absolute', top: '47.5%', left: '64%' }}>
                        {ImageSquareBigSpace()}
                    </div>
                </>
            }
            {
                numberForm.minute !== '' && friendNumberForm.minute !== '' ? <>
                    <div style={{ transform: 'scale(0.4)', position: 'absolute', top: '39.5%', left: '72%' }}>
                        {ImageSquareBig(numberForm.MainNumber[4], friendNumberForm.MainNumber[4], numberForm.MainNumber[9], friendNumberForm.MainNumber[9])}
                    </div>
                </> : <>
                    <div style={{ transform: 'scale(0.4)', position: 'absolute', top: '39.5%', left: '72%' }}>
                        {ImageSquareBigSpace()}
                    </div>
                </>
            }
            <div style={{ transform: 'scale(0.35)', position: 'absolute', top: '48%', left: '-64.8%' }}>
                {ImageSquareList(numberForm, friendNumberForm)}
            </div>
            <div style={{ transform: 'scale(0.35)', fontSize: '22px', position: 'absolute', top: '87%', left: '69.5%', whiteSpace: 'nowrap' }}>
                {advform.adviceDate}
            </div>
            <div style={{ transform: 'scale(0.4)', fontSize: '28px', position: 'absolute', top: '86%', left: '56.5%' }}>
                {advform.adviceName}
            </div></>)}
        </>
    }
    return (
        <Wrapper>
            <Slider {...sliderSettings} ref={ResultSlider}>
                <Content>
                    <Title>輸入你的生日</Title>
                    <InputDateBar inputTitle='年、月、日 (單月、日要多加一個0，例:1983-01-03)' className='top'>
                        <Space direction="vertical">
                            <DatePicker className={alert ? 'alert' : ''} placeholder={date} onChange={onChange} require />
                        </Space>
                    </InputDateBar>
                    <InputDateBar inputTitle='時、分' className='top'>
                        <TimePicker
                            //defaultValue={moment('00:00', 'HH:mm')}
                            value={time === '' ? moment('00:00', 'HH:mm') : moment(time, 'HH:mm')}
                            onChange={handleTimeChange}
                            format='HH:mm'
                            allowClear={false}
                            className={`${check ? 'disable' : ''}`}
                            disabled={check ? true : false}
                            inputReadOnly
                            clearIcon={false}
                            showNow={false}
                            onBlur={handleTimeBlur}
                        />
                    </InputDateBar>
                    <div className="checkbox-container">
                        <CheckBar className='blue'>
                            <input type="checkbox" name="minute" id='minute' value={only_hour} onChange={(e) => handleCheckMinute(e)} />
                            <span htmlFor="minute">只忘記分</span>
                        </CheckBar>
                        <CheckBar className='blue'>
                            <input type="checkbox" name="time" id='time' value={check} onChange={(e) => handleCheck(e)} />
                            <span htmlFor="time">忘記時、分</span>
                        </CheckBar>
                    </div>
                    <form id='form' >
                        <ContentArea>
                            <InputBar inputTitle='姓名' className='top'>
                                <input id='name' name='name' type='text' placeholder='請輸入姓名' maxLength={10} value={name} onChange={(e) => handleChange(e)} />
                            </InputBar>
                            <InputBar inputTitle='性別' className='top'>
                                <input id='gender' name='gender' type='text' placeholder='請輸入性別' maxLength={2} value={gender} onChange={(e) => handleChange(e)} />
                            </InputBar>
                        </ContentArea>
                    </form>

                    &nbsp;&nbsp;
                    &nbsp;&nbsp;
                    <Title>輸入對方的生日</Title>
                    <InputDateBar inputTitle='年、月、日 (單月、日要多加一個0，例:1983-01-03)' className='top'>
                        <Space direction="vertical">
                            <DatePicker className={friend_alert ? 'alert' : ''} placeholder='2000-01-01' onChange={onFriendChange} require />
                        </Space>
                    </InputDateBar>
                    <InputDateBar inputTitle='時、分' className='top'>
                        <TimePicker
                            //defaultValue={moment('00:00', 'HH:mm')}
                            value={friend_time === '' ? moment('00:00', 'HH:mm') : moment(friend_time, 'HH:mm')}
                            onChange={handleFriendTimeChange}
                            format='HH:mm'
                            allowClear={false}
                            className={`${friend_check ? 'disable' : ''}`}
                            disabled={friend_check ? true : false}
                            inputReadOnly
                            clearIcon={false}
                            showNow={false}
                            onBlur={handleFriendTimeBlur}
                        />
                    </InputDateBar>
                    <div className="checkbox-container">
                        <CheckBar className='blue'>
                            <input type="checkbox" name="minute" id='minute' value={friend_only_hour} onChange={(e) => handleFriendCheckMinute(e)} />
                            <span htmlFor="minute">只忘記分</span>
                        </CheckBar>
                        <CheckBar className='blue'>
                            <input type="checkbox" name="time" id='time' value={friend_check} onChange={(e) => handleFriendCheck(e)} />
                            <span htmlFor="time">忘記時、分</span>
                        </CheckBar>
                    </div>

                    <form id='form' >
                        <ContentArea>
                            <InputBar inputTitle='對方姓名' className='top'>
                                <input id='friend_name' name='friend_name' type='text' placeholder='請輸入對方姓名' maxLength={10} value={friend_name} onChange={(e) => handleChange(e)} />
                            </InputBar>
                            <InputBar inputTitle='對方性別' className='top'>
                                <input id='friend_gender' name='friend_gender' type='text' placeholder='請輸入對方性別' maxLength={2} value={friend_gender} onChange={(e) => handleChange(e)} />
                            </InputBar>
                            <InputBar inputTitle='諮詢師' className='top'>
                                <input id='adviceName' name='adviceName' type='text' placeholder='請輸入諮詢師' maxLength={16} value={advform.adviceName} onChange={(e) => handleAdvChange(e)} />
                            </InputBar>
                        </ContentArea>
                    </form>
                    <InputDateBar inputTitle='諮詢日期' className='top'>
                        <input id='adviceDate' name='adviceDate' className={date ? 'no-placeholder' : ''} type='date' value={advform.adviceDate} onChange={e => handleAdvChange(e)} />
                        <img src={Images.duedate_w} alt='duedate' />
                    </InputDateBar>
                </Content>
                {index >= 1 &&
                    <Content id={'rainbowimg'}>
                        {width<568?mobileRenderAll():renderAll()}
                    </Content>
                }
            </Slider>
            {index === 0 &&
                <BtnArea>
                    <CalculateBtn
                        className="prev solid lg"
                        btnName='下載空白諮詢單'
                        onClick={() => handleDownloadWhite()}
                    />
                    <CalculateBtn
                        className={date ? 'submit solid lg' : 'solid submit disable lg'}
                        btnName='立即試算'
                        onClick={() => Submit()}
                    />
                </BtnArea>
            }
            {index === 1 &&
                <BtnArea>
                    <CalculateBtn
                        className="prev solid lg"
                        btnName='下載諮詢單'
                        onClick={() => handleDownload()}
                    />
                    <CalculateBtn
                        className='solid lg'
                        btnName={(numberForm.add_month || friendNumberForm.add_month) ? '計算閏月組合' : '算算其他生日'}
                        onClick={(numberForm.add_month || friendNumberForm.add_month) ? gotoNext : Restart}
                    />
                </BtnArea>
            }
            {index === 2 &&
                <BtnArea>
                    <CalculateBtn
                        className="prev solid lg"
                        btnName='下載諮詢單'
                        onClick={() => handleDownload()}
                    />
                    <CalculateBtn
                        className='solid lg'
                        btnName={(scenario === 4) ? '再計算閏月組合' : '算算其他生日'}
                        onClick={(scenario === 4) ? gotoNext : Restart}
                    />
                </BtnArea>
            }
            {index === 3 &&
                <BtnArea>
                    <CalculateBtn
                        className="prev solid lg"
                        btnName='下載諮詢單'
                        onClick={() => handleDownload()}
                    />
                    <CalculateBtn
                        className='solid lg'
                        btnName='再計算閏月組合'
                        onClick={gotoNext}
                    />
                </BtnArea>
            }
            {index === 4 &&
                <BtnArea>
                    <CalculateBtn
                        className="prev solid lg"
                        btnName='下載諮詢單'
                        onClick={() => handleDownload()}
                    />
                    <CalculateBtn
                        className='solid lg'
                        btnName='算算其他生日'
                        onClick={Restart}
                    />
                </BtnArea>
            }
        </Wrapper>
    );
}

export default Calculator;
