import React, { useState, useRef } from 'react';
import {
    Seo, Layout, TitleArea, CardA, StoryRight, GridCol2, GridCol3,
    Rounded, CardWitness, ContactModal, LiHungModal, ImgCard2
} from '../components'
import styled from 'styled-components'
import { Colors, Images } from '../themes'
import Slider from "react-slick"
import Modal from 'react-modal'

const Wrapper = styled.div`
    padding: 0 16px;
    .slick-slider{
        width: 90%;
        max-width: 1140px;
        margin: 0 auto;
        padding: 0 32px;
    }
    .slick-track{
        padding: 30px 0;
    }
    .slick-disabled{
        opacity: 0 !important;
    }
    .slick-arrow::before{
        display: none;
    }
    @media (max-width: 568px){
        .slick-slider{
            width: 100%;
            padding: 0 0 0 16px;
        }
        .slick-arrow{
            display: none !important;
        } 
    }
`
const Close = styled.div`
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 10000;
    width: 24px;
    height: 24px;
    &:hover{
        cursor: pointer;
    }
`

const customStyles = {
    overlay: {
        backgroundColor: "rgba(0,0,0,0.5)",
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '16px',
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.1)',
        zIndex: '102',
        width: 'calc(100% - 40px)',
        height: 'calc(100% - 40px)',
        maxHeight: '1080px',
    }
};

const customStyles2 = {
    overlay: {
        backgroundColor: "rgba(0,0,0,0.5)",
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '16px',
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.1)',
        zIndex: '102',
        width: 'calc(100% - 40px)',
        height: 'calc(100% - 40px)',
        maxWidth: '520px',
        maxHeight: '1080px',
        overflow: 'hidden',
        display: 'block',
    }
};

function CardNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: "grid",
            }}
            onClick={onClick}
        >
            <Rounded
                className='arrow'
                icon={Images.arrow_right}
                alt='arrow-right'
                bg={Colors.W100}
                filter=''
            />
        </div>
    );
}

function CardPrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: "grid",
            }}
            onClick={onClick}
        >
            <Rounded
                className='arrow'
                icon={Images.arrow_left}
                alt='arrow-left'
                bg={Colors.W100}
            />
        </div>
    );
}
const ReservationPage = (props) => {
    const ResultSlider = useRef();
    const [sliderSettings] = useState({
        infinite: false,
        arrows: true,
        speed: 300,
        cssEase: `linear`,
        draggable: true,
        variableWidth: true,
        nextArrow: <CardNextArrow />,
        prevArrow: <CardPrevArrow />,
    })
    const [cehckValue, setCheckValue] = useState();
    const [modalOneIsOpen, setOneIsOpen] = useState(false);

    function openModalOne() {
        setOneIsOpen(true);
    }
    function closeModalOne() {
        setOneIsOpen(false);
    }

    const [ContactIsOpen, setContact] = useState(false);

    function openContact(data) {
        setCheckValue(data)
        setContact(true);
    }
    function closeContact() {
        setContact(false);
    }
    function handleUrl(url) {
        console.log(url)
        window.open(url, '_blank', 'noopener,noreferrer')
    }

    return (
        <Layout reservation>
            <Seo
                title='彩虹數字學會 - 預約諮詢'
                metaDescription='運用彩虹數字解讀你的生命密碼，了解自己的生命課題。學習「心性解碼」、「時間解碼」、「感情解碼」課程，免費試算自己的數字。'
                metaTitle='彩虹數字學會 - 預約諮詢'
                metaUrl='https://www.rainbownumen.org/reservation'
                //metaImage={`${Images.Rainbow_SEO}`}
                metaImage='https://rainbow-digital.glitch.me/i/ktlc6ygb.png'
                metaImageAlt=''
            />
            <Wrapper>
                {window.innerWidth > 768 ?
                    <TitleArea
                        title='讓彩虹數字回答您生命課題中的所有疑惑'
                        text='由專業諮詢師運用彩虹數字解析您前世今生的靈魂藍圖計畫，協助您喚醒無限潛能，'
                        text1='開展您的事業、感情、家庭、健康、財富上的靈性感知以及豐盛人生。'
                    /> : <TitleArea
                        title='讓彩虹數字回答您生命課題中的所有疑惑'
                        text='由專業諮詢師運用彩虹數字解析您前世今生的靈魂藍圖計畫，協助您喚醒無限潛能，開展您的事業、感情、家庭、健康、財富上的靈性感知以及豐盛人生。'
                    />
                }
                <GridCol2>
                    <CardA
                        className='card'
                        title='感情/人際關係'
                        text='洞察您與伴侶、家人、同學、同事、朋友、欲交往對象之間的前世今生、緣分關係和深層情感需求；協助您找出彼此之間最適合的相處互動模式，據以全面改善、修復關係。'
                        img={Images.Reservation_01}
                        onClick={() => window.location.href = 'https://line.me/R/ti/p/%40289exmlj'}
                        linkText='預約諮詢'
                    />
                    <CardA
                        className='card'
                        title='學業/事業/職涯探索'
                        text='剖析您如何針對與生俱來的稟賦特長，選擇適合自己的科系、工作、志向；協助您激發內在潛能，進而導引出源源不絕的熱情與動力，讓自已樂在學業、事業及職涯當中。'
                        img={Images.Reservation_02}
                        onClick={() => window.location.href = 'https://line.me/R/ti/p/%40289exmlj'}
                        linkText='預約諮詢'
                    />
                    <CardA
                        className='card'
                        title='自我/人格特質解析'
                        text='解碼您個人獨一無二的人格特質，並挖掘內在與生俱足的能量寶藏，藉以掌握生命藍圖；讓您有意識地因應(面對甚至超越)內在衝突、矛盾、情緒及關鍵難題，做自己生命的主人。'
                        img={Images.Reservation_03}
                        onClick={() => window.location.href = 'https://line.me/R/ti/p/%40289exmlj'}
                        linkText='預約諮詢'
                    />
                    <CardA
                        className='card'
                        title='流年規劃/解答各種生命課題'
                        text='解答您所亟欲探詢之各式各樣生命課題和疑惑(當下困境與前世今生的連結)；協助您檢視及規劃短、中及長期人生階段目標，讓您能運用與生俱足能量、自由自在活在當下。'
                        img={Images.Reservation_04}
                        onClick={() => window.location.href = 'https://line.me/R/ti/p/%40289exmlj'}
                        linkText='預約諮詢'
                    />
                </GridCol2>
                <TitleArea
                    title='學會理事長'

                />
                <StoryRight
                    title='麗子老師'
                    jobTitle=''
                    text='中華民國彩虹數字學會理事長。除從事彩虹數字學之研究、教學、諮詢、指導之外，並任教於國立台北科技大學，開設藝術欣賞課程。著有「麗子的彩虹數字學」、「原來藝術這麼簡單Ⅰ-史前到中世紀的西方藝術」、「國會助理工作手冊」、「兩岸關係的決策分析」等專書。'
                    onClick={openModalOne}
                    onClick1={e => handleUrl('https://rainbownumen.pse.is/weekie_fiona')}
                    img={Images.ceo}
                />
                <TitleArea
                    title='學會認證諮詢師'
                    text='彩虹數字學會秉持學術專業，由麗子老師親自培訓、考核、認證，致力培養以專業解析、提供精準建議與正面能量，協助生命揚升的諮詢師。'
                />
                <GridCol3>
                    <ImgCard2
                        img={Images.Tina}
                        title='娜米'
                        jobTitle='老師'
                        text='無論你遇到什麼難題，我都會協助你瞭解自己目前的狀況，並給予你人生方向最合適的建議。相信你能更加了解自己，更有勇氣與力量。'
                        onClick={e => handleUrl('https://rainbownumen.pse.is/weekie_nami')}
                    />
                    <ImgCard2
                        img={Images.Chen}
                        title='淨榆'
                        jobTitle='老師'
                        text='臺大法律系畢業，善於聆聽、洞察核心，並提供具體建議。願協助您有效梳理疑惑，深度了解自己與他人關係，擁有更和諧愉快的自己！'
                        onClick={e => handleUrl('https://rainbownumen.pse.is/weekie_hedy')}
                    />
                    <ImgCard2
                        img={Images.Lee}
                        title='伊弗'
                        jobTitle='老師'
                        text='超過10年於業務品牌行銷領域之顧問管理經驗。在事業/職涯發展、新人類素養教育中，以個人生命藍圖為本，提供適時指引，達到身心靈平衡。'
                        onClick={e => handleUrl('https://rainbownumen.pse.is/weekie_yve')}
                    />
                </GridCol3>
                <GridCol3>
                    <ImgCard2
                        img={Images.Chen1}
                        title='Kobe'
                        jobTitle='老師'
                        text='擅長於發掘優勢能量、探索自我、生涯規劃、親子關係等面向，解答生命中的各種疑難雜症。'
                        onClick={e => handleUrl('https://rainbownumen.pse.is/weekie_kobe')}
                    />
                    <ImgCard2
                        img={Images.t4}
                        title='Joannah'
                        jobTitle='老師'
                        text='當你觸碰到內心最真實的渴望，那會是打從心底的感動。協助你在生命不同面向中，找到自己的平衡點，綻放天賦自我定位，創化出無限可能！'
                        onClick={e => handleUrl('https://rainbownumen.pse.is/weekie_joannah')}
                    />
                    <ImgCard2
                        img={Images.t1}
                        title='Tara'
                        jobTitle='老師'
                        text='擅長以量子意識觀解讀人生、情感議題。跟隨數字能量的指引，陪伴您一同探索，解開被封印的生命檔案，喚醒自身使命。'
                        onClick={e => handleUrl('https://rainbownumen.pse.is/weekie_tara')}
                    />
                </GridCol3>
                <GridCol3>
                    <ImgCard2
                        img={Images.t2}
                        title='Monica'
                        jobTitle='老師'
                        text='生命的所有課題都在數字能量裡流轉擺盪，順流或逆流都是禮物；我祈願協助有緣人一起探索彩虹次元的生命方程式！'
                        onClick={e => handleUrl('https://rainbownumen.pse.is/weekie_monica')}
                    />
                    <ImgCard2
                        img={Images.t3}
                        title='Cindy'
                        jobTitle='老師'
                        text='每個人一生都想遇見理解自己的人，讓數字帶領了解自己的獨特，清楚今生課題，用最真實的自己，回應這世界。'
                        onClick={e => handleUrl('https://rainbownumen.pse.is/weekie_cindy')}
                    />
                    <ImgCard2
                        img={Images.t9}
                        title='紫晴'
                        jobTitle='老師'
                        text='超過20年以上護理經驗，善於傾聽，過程中隨著數字解密解開內心疑惑及狀態與他人關係，針對問題，提供建議！'
                        onClick={e => handleUrl('https://rainbownumen.pse.is/weekie_vera')}
                    />
                </GridCol3>
                <GridCol3>
                    <ImgCard2
                        img={Images.t5}
                        title='苡穆'
                        jobTitle='老師'
                        text='帶著超過十年擔任社工的經驗，及對八字、中醫、托特塔羅和排列等的涉略，期待與你一起開挖專屬於自己的寶藏！'
                        onClick={e => handleUrl('https://rainbownumen.pse.is/weekie_bunny')}
                    />
                    <ImgCard2
                        img={Images.t6}
                        title='麥笛'
                        jobTitle='老師'
                        text='「不要拿著別人的地圖，找自己的路」我將透過數字來協助你縮短人生的焦慮，找到屬於自己的定位與方向。我可以，當然你也可以！'
                        onClick={e => handleUrl('https://rainbownumen.pse.is/weekie_rabbit')}
                    />
                    <ImgCard2
                        img={Images.t8}
                        title='柔潔'
                        jobTitle='老師'
                        text='喜愛與外星人聊彩虹數字，前世今生、雙生火焰，潛能開展⋯特別奇怪的問題都歡迎來諮詢。可協助：深度感知，選擇新的能量，開展可能性。'
                        onClick={e => handleUrl('https://rainbownumen.pse.is/weekie_pearl')}
                    />
                </GridCol3>
                <GridCol3>
                    <ImgCard2
                        img={Images.t7}
                        title='Una'
                        jobTitle='老師'
                        text='我是UNA，彩虹數字是一個快速覺察及調整自己的工具，當你有任何困擾或疑惑，歡迎來找我聊聊 ^_^'
                        onClick={e => handleUrl('https://rainbownumen.pse.is/weekie_una')}
                    />
                </GridCol3>
                <TitleArea
                    title='彩虹數字學員見證'
                    text='彩虹數字與我們的未來發展息息相關，聽聽他們接觸彩虹數字前後的改變' />
                <Slider {...sliderSettings} ref={ResultSlider}>
                    <CardWitness
                        title='Dix'
                        text='彩虹數字真的給我很大的幫助，讓我更認識自己，找到自己的潛能。並且也學習到如何與人溝通。讓我和家人相處得更融洽。田老師的正向能量，幫助我不僅僅是學到數字，而是學到更多寶貴的觀念。真的很高興可以參與到這些課程，幫助自己也幫助身邊的人！'
                        img={Images.witness_01}
                    />
                    <CardWitness
                        title='Ryan'
                        text='原來每個人會來到地球，都是有任務或是來修正身心靈的，最終回到原本的家。彩虹數字是個了解自己、自助助人的好學問。田老師真的非常的讚，讓我想要再與她學習更多。'
                        img={Images.witness_02}
                    />
                    <CardWitness
                        title='Cindy'
                        text='對自己與他人有更多的理解與同理，並找到與之相處最適合的方式。用高階方法處理低階狀態，這真是絕妙，不止運用在數字能量上，在其它事物上也能如此運用。老師有許多話皆如醍醐灌頂，讓學生信而不迷，知而不困，很高興有機會認識田老師。'
                        img={Images.witness_03}
                    />
                </Slider>
            </Wrapper>
            <Modal
                isOpen={modalOneIsOpen}
                onRequestClose={closeModalOne}
                style={customStyles2}
                contentLabel="Example Modal"
            >
                <Close onClick={closeModalOne}>
                    <img src={Images.close} alt='' />
                </Close>
                <LiHungModal />
            </Modal>
            {/* <Modal
                isOpen={ContactIsOpen}
                onRequestClose={closeContact}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <Close onClick={closeContact}>
                    <img src={Images.close} alt='' />
                </Close>
                <ContactModal 
                    check={cehckValue}
                    onClick={closeContact}
                />
            </Modal> */}
        </Layout>
    );
}

export default ReservationPage;