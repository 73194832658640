const Images = {
    Rainbow_SEO: require('../img/Rainbow_SEO.png'),
    galaxy_low: require('../img/Modal/galaxy_low.jpg'),
    //Home
    Logo: require('../img/Rainbow-Logo.svg'),
    Logo_Footer: require('../img/Logo/Logo-Footer.png'),
    Logo_Header: require('../img/Logo/Logo-Header.svg'),
    Class: require('../img/class.png'),
    class_mobile: require('../img/class_mobile.png'),
    cover: require('../img/banner/cover.svg'),
    banner_02: require('../img/banner/banner_02.svg'),
    banner_01: require('../img/banner/banner_01.svg'),
    arrow_right: require('../img/Icon/arrow_right.svg'),
    galaxy_01: require('../img/Modal/galaxy_01.png'),
    word: require('../img/word.png'),
    white: require('../img/white.png'),
    enterprise: require('../img/enterprise.jpg'),
    word_emo: require('../img/word_emo.jpg'),
    loading: require('../img/loading.jpg'),
    qrcode: require('../img/qrcode.png'),
    emo_white: require('../img/emo_white.png'),
    //Reservation
    Reservation_01: require('../img/Card/Reservation-01.svg'),
    Reservation_02: require('../img/Card/Reservation-02.svg'),
    Reservation_03: require('../img/Card/Reservation-03.svg'),
    Reservation_04: require('../img/Card/Reservation-04.svg'),
    Reservation_05: require('../img/Card/Reservation-05.svg'),
    Reservation_06: require('../img/Card/Reservation-06.svg'),
    Reservation_07: require('../img/Card/Reservation-07.svg'),
    Reservation_08: require('../img/Card/Reservation-08.svg'),
    Reservation_09: require('../img/Card/Reservation-09.svg'),

    //Team
    intro_1: require('../img/Team/intro_1.svg'),
    intro_2: require('../img/Team/intro_2.svg'),
    intro_3: require('../img/Team/intro_3.svg'),
    intro_4: require('../img/Team/intro_4.svg'),
    Tina: require('../img/Team/Tina.png'),
    license: require('../img/Team/license.png'),
    ceo: require('../img/Team/ceo.png'),
    ask: require('../img/Noti/ask.svg'),
    calculate_01: require('../img/Noti/calculate-01.svg'),
    calculate_02: require('../img/Noti/calculate-02.svg'),
    calculate_03: require('../img/Noti/calculate-03.svg'),
    calculate_04: require('../img/Noti/calculate-04.svg'),
    calculate_05: require('../img/Noti/calculate-05.svg'),
    Chen: require('../img/Team/Chen.png'),
    Chen1: require('../img/Team/Chen1.png'),
    Lee: require('../img/Team/Lee.png'),
    success: require('../img/Noti/success.svg'),
    error: require('../img/Noti/Error.svg'),
    t1: require('../img/Team/t1.jpg'),
    t2: require('../img/Team/t2.jpg'),
    t3: require('../img/Team/t3.jpg'),
    t4: require('../img/Team/t4.jpg'),
    t5: require('../img/Team/t5.jpg'),
    t6: require('../img/Team/t6.jpg'),
    t7: require('../img/Team/t7.jpg'),
    t8: require('../img/Team/t8.jpg'),
    t9: require('../img/Team/t9.jpg'),
    t10: require('../img/Team/t10.jpg'),
    t11: require('../img/Team/t11.jpg'),
    c1: require('../img/soul/c1.png'),
    c2: require('../img/soul/c2.png'),
    c3: require('../img/soul/c3.png'),
    c4: require('../img/soul/c4.png'),
    c5: require('../img/soul/c5.png'),
    c6: require('../img/soul/c6.png'),
    c7: require('../img/soul/c7.png'),
    slash: require('../img/soul/slash.png'),
    lyear: require('../img/soul/lyear.png'),
    square: require('../img/soul/square.png'),
    squarelist: require('../img/soul/squarelist.png'),
    //Card
    Event_01: require('../img/Card/Event-01.svg'),
    Event_02: require('../img/Card/Event-02.svg'),
    Event_03: require('../img/Card/Event-03.svg'),
    Event_04: require('../img/Card/Event-04.svg'),
    Event_05: require('../img/Card/Event-05.svg'),
    Event_06: require('../img/Card/Event-06.svg'),
    Event_07: require('../img/Card/Event-07.svg'),
    Event_08: require('../img/Card/Event-08.svg'),
    Event_09: require('../img/Card/Event-09.svg'),
    Event_10: require('../img/Card/Event-10.svg'),
    Event_11: require('../img/Card/Event-11.svg'),
    Event_12: require('../img/Card/Event-12.svg'),
    Top: require('../img/Card/Modal-Top.svg'),
    News_01: require('../img/Card/News-01.png'),
    News_02: require('../img/Card/News-02.png'),
    News_03: require('../img/Card/News-03.png'),
    witness_01: require('../img/Card/witness_01.svg'),
    witness_02: require('../img/Card/witness_02.svg'),
    witness_03: require('../img/Card/witness_03.svg'),


    //Payment
    apple: require('../img/Payment/apple.svg'),
    cubank: require('../img/Payment/cubank.png'),
    fami: require('../img/Payment/fami.png'),
    master: require('../img/Payment/master.svg'),
    street: require('../img/Payment/street.png'),

    //Icon
    add: require('../img/Icon/add.svg'),
    alert: require('../img/Icon/alert.svg'),
    arrow_down: require('../img/Icon/arrow_down.svg'),
    arrow_left: require('../img/Icon/arrow_left.svg'),
    // arrow_right: require('../img/Icon/arrow_right.svg'),
    arrow_up: require('../img/Icon/arrow_up.svg'),
    birthday: require('../img/Icon/birthday.svg'),
    book: require('../img/Icon/book.svg'),
    calendar: require('../img/Icon/calendar.svg'),
    check_circle: require('../img/Icon/check_circle.svg'),
    check: require('../img/Icon/check.svg'),
    checkbox_n: require('../img/Icon/checkbox_n.svg'),
    checkbox_s: require('../img/Icon/checkbox_s.svg'),
    clock: require('../img/Icon/clock.svg'),
    clock_w: require('../img/Icon/clock_w.svg'),
    close: require('../img/Icon/close.svg'),
    close_w: require('../img/Icon/close_w.svg'),
    credit: require('../img/Icon/credit.svg'),
    duedate: require('../img/Icon/duedate.svg'),
    duedate_w: require('../img/Icon/duedate_w.svg'),
    facebook: require('../img/Icon/facebook.svg'),
    fill_arrow_down: require('../img/Icon/fill_arrow_down.svg'),
    fill_arrow_up: require('../img/Icon/fill_arrow_up.svg'),
    gender: require('../img/Icon/gender.svg'),
    globe: require('../img/Icon/globe.svg'),
    google: require('../img/Icon/google.svg'),
    instagram: require('../img/Icon/instagram.svg'),
    location: require('../img/Icon/location.svg'),
    logout: require('../img/Icon/logout.svg'),
    mail: require('../img/Icon/mail.svg'),
    medal: require('../img/Icon/medal.svg'),
    menu: require('../img/Icon/menu.svg'),
    mic: require('../img/Icon/mic.svg'),
    money: require('../img/Icon/money.svg'),
    name: require('../img/Icon/name.svg'),
    notice: require('../img/Icon/notice.svg'),
    number: require('../img/Icon/number.svg'),
    pencil: require('../img/Icon/pencil.svg'),
    phone: require('../img/Icon/phone.svg'),
    profile: require('../img/Icon/profile.svg'),
    radio_n: require('../img/Icon/radio_n.svg'),
    radio_s: require('../img/Icon/radio_s.svg'),
    search: require('../img/Icon/search.svg'),
    send: require('../img/Icon/send.svg'),
    star: require('../img/Icon/star.svg'),
    tag: require('../img/Icon/tag.svg'),
    ticket: require('../img/Icon/ticket.svg'),
    time: require('../img/Icon/time.svg'),
    line: require('../img/Icon/line.svg'),
    minus: require('../img/Icon/minus.svg'),

    //MNoti
    login: require('../img/Noti/login.svg'),

}

export default Images;